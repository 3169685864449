import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';
class CreateCertTjMg extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
     docEmt:'CERTIDÃO',
     referencia: '',
     codigo_fiscal_ato: '',
     codigo_seguranca: '',
     serventia: '',
     data_pratica_ato: '',
     data_hora_utilizacao_selo: '',
     data_hora_transmissao_ato: '',
     tipo_tributacao: '',
     valor_emolumento: '',
     valor_taxa_fiscalizacao_judiciaria: '',
     valor_final_usuario: '',
     protocolo: '',
     tipo_livro: '',
     ordem_sequencial: '',
     folha: '',
     tipo_escrituracao: '',
     numero_ordem: '',
     especie_certidao: '',
     detalhamento_certidao: '',
     nome_pessoa: '',
     complemento_certidao: ''

   };
    
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  componentDidMount() {
    document.title = "Emitir Certidão TJMg ";
    fetch(`${apiUrl}/listar-cartorios/`+window.location.pathname.split("/")[1], {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}
      })
}

 


  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight"> Emitir Certidão TJMg  </h2></div></header>
        <main>
        <div class="py-12">
   <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
       
            <div class="card">
               <div class="card-header">
                  <h3>Emissão de certidão:</h3>
               </div>
               <div class="card-body">
   <div class="form-group">
      <label>Nome da Serventia:</label>
      <select class="form-control form-control-sm" onChange={(e)=>{
                      this.setState({nomeSer:e.target.value})
                     }}>
                     <option style={{display:'none'}}></option>
                        {this.state.data.map((item,index)=>{
                          
                            return(<>
                            <option value={item.id}>{item.nome}</option>
                            </>)
                        })}
                       
                     </select>
   </div>
  
   <div class="form-group mt-2">
                     <label>Tipo do ato:</label>
                     <select class="form-control form-control-sm"  onChange={(e)=>{
                      this.setState({docEmt:e.target.value})
                     }}>
                       
                       {[
    { value: "CERTIDÃO", label: "CERTIDÃO" },
    { value: "CERTIDÃO DE NASCIMENTO", label: "CERTIDÃO DE NASCIMENTO" },
    { value: "CERTIDÃO DE CASAMENTO", label: "CERTIDÃO DE CASAMENTO" },
    { value: "CERTIDÃO DE ÓBITO", label: "CERTIDÃO DE ÓBITO" },
    { value: "PROCURAÇÃO PÚBLICA", label: "PROCURAÇÃO PÚBLICA" },
    { value: "ESCRITURA PÚBLICA", label: "ESCRITURA PÚBLICA" },
    { value: "NASCIMENTO INTEIRO TEOR", label: "NASCIMENTO INTEIRO TEOR" },
    { value: "CASAMENTO BREVE RELATO", label: "CASAMENTO BREVE RELATO" },
    { value: "CASAMENTO INTEIRO TEOR", label: "CASAMENTO INTEIRO TEOR" },
    { value: "ÓBITO BREVE RELATO", label: "ÓBITO BREVE RELATO" },
    { value: "ÓBITO INTEIRO TEOR", label: "ÓBITO INTEIRO TEOR" },
    { value: "DECLARAÇÃO", label: "DECLARAÇÃO" },
    { value: "PROCURAÇÃO", label: "PROCURAÇÃO" },
].map((option, index) => (
   <option key={index} value={option.value}>
       {option.label}
   </option>
))}
                     </select>
                  </div>
                  <div className="form-group mt-2">
          <label>Referência:</label>
          <input type="text" name="referencia" className="form-control form-control-sm" onChange={this.handleChange} />
        </div>
        <div className="form-group mt-2">
          <label>Código Fiscal do Ato:</label>
          <input type="text" name="codigo_fiscal_ato" className="form-control form-control-sm" onChange={this.handleChange} />
        </div>
        <div className="form-group mt-2">
          <label>Código de Segurança:</label>
          <input type="text" name="codigo_seguranca" className="form-control form-control-sm" onChange={this.handleChange} />
        </div>
        
        <div className="form-group mt-2">
          <label>Data da Prática do Ato:</label>
          <input type="text" name="data_pratica_ato" className="form-control form-control-sm" onChange={this.handleChange} />
        </div>
        <div className="form-group mt-2">
          <label>Data e Hora de Utilização do Selo:</label>
          <input type="text" name="data_hora_utilizacao_selo" className="form-control form-control-sm" onChange={this.handleChange} />
        </div>
        <div className="form-group mt-2">
          <label>Data e Hora de Transmissão do Ato:</label>
          <input type="text" name="data_hora_transmissao_ato" className="form-control form-control-sm" onChange={this.handleChange} />
        </div>
        <div className="form-group mt-2">
          <label>Tipo de Tributação:</label>
          <input type="text" name="tipo_tributacao" className="form-control form-control-sm" onChange={this.handleChange} />
        </div>
        <div className="form-group mt-2">
          <label>Valor do Emolumento:</label>
          <input type="text" step="0.01" name="valor_emolumento" className="form-control form-control-sm" onChange={this.handleChange} />
        </div>
        <div className="form-group mt-2">
          <label>Valor da Taxa de Fiscalização Judiciária:</label>
          <input type="text" step="0.01" name="valor_taxa_fiscalizacao_judiciaria" className="form-control form-control-sm" onChange={this.handleChange} />
        </div>
        <div className="form-group mt-2">
          <label>Valor Final do Usuário:</label>
          <input type="text" step="0.01" name="valor_final_usuario" className="form-control form-control-sm" onChange={this.handleChange} />
        </div>
        <div className="form-group mt-2">
          <label>Protocolo:</label>
          <input type="text" name="protocolo" className="form-control form-control-sm" onChange={this.handleChange} />
        </div>
      
        <div className="form-group mt-2">
          <label>Ordem Sequencial:</label>
          <input type="text" name="ordem_sequencial" className="form-control form-control-sm" onChange={this.handleChange} />
        </div>
        <div className="form-group mt-2">
          <label>Folha:</label>
          <input type="text" name="folha" className="form-control form-control-sm" onChange={this.handleChange} />
        </div>
        <div className="form-group mt-2">
          <label>Tipo de Escrituração:</label>
          <input type="text" name="tipo_escrituracao" className="form-control form-control-sm" onChange={this.handleChange} />
        </div>
        <div className="form-group mt-2">
          <label>Número da Ordem:</label>
          <input type="text" name="numero_ordem" className="form-control form-control-sm" onChange={this.handleChange} />
        </div>
        <div className="form-group mt-2">
          <label>Espécie da Certidão:</label>
          <input type="text" name="especie_certidao" className="form-control form-control-sm" onChange={this.handleChange} />
        </div>
        <div className="form-group mt-2">
          <label>Detalhamento da Certidão:</label>
          <textarea name="detalhamento_certidao" className="form-control form-control-sm" onChange={this.handleChange}></textarea>
        </div>
        <div className="form-group mt-2">
          <label>Nome da Pessoa:</label>
          <input type="text" name="nome_pessoa" className="form-control form-control-sm" onChange={this.handleChange} />
        </div>
        <div className="form-group mt-2">
          <label>Complemento da Certidão:</label>
          <textarea name="complemento_certidao" className="form-control form-control-sm" onChange={this.handleChange}></textarea>
        </div>
   
        
   
   </div>

               <div class="card-footer">
               <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}><FontAwesomeIcon icon={faArrowLeft} />  Voltar</button>
                  
               <button type="submit" class="btn btn-primary float-right ml-2" onClick={()=>{
  
                if(this.state.nomeSer == undefined){
                  toast.error('Selecione o nome da Serventia!', {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                     });
                  return false;
                }
                if(this.state.nomeSer == ""){
                  toast.error('Selecione o nome da Serventia!', {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                     });
                  return false;
                }

               // const id = toast.loading("Enviando...");
                var body = this.state;
         
               

               
               
               
          
                fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/criar-certificado/`, {
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+Cookies.get("auth_token"),
                  },
      body:JSON.stringify({
        serventia:body.nomeSer,
        referencia: body.referencia,
codigo_fiscal_ato: body.codigo_fiscal_ato,
codigo_seguranca: body.codigo_seguranca,
data_pratica_ato: body.data_pratica_ato,
data_hora_utilizacao_selo: body.data_hora_utilizacao_selo,
data_hora_transmissao_ato: body.data_hora_transmissao_ato,
tipo_tributacao: body.tipo_tributacao,
valor_emolumento: body.valor_emolumento,
valor_taxa_fiscalizacao_judiciaria: body.valor_taxa_fiscalizacao_judiciaria,
valor_final_usuario: body.valor_final_usuario,
protocolo: body.protocolo,
tipo_livro: body.docEmt,
ordem_sequencial: body.ordem_sequencial,
folha: body.folha,
tipo_escrituracao: body.tipo_escrituracao,
numero_ordem: body.numero_ordem,
especie_certidao: body.especie_certidao,
detalhamento_certidao: body.detalhamento_certidao,
nome_pessoa: body.nome_pessoa,
complemento_certidao: body.complemento_certidao
       
      })
                }).then(response => {
if(response.status == 200){
   //toast.update(id, { render:"Certificado criado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
   
   response.json().then(data=>{
this.props.navigate(`/${window.location.pathname.split("/")[1]}/preview/${data.token}`)
   })
}
})

               }}><FontAwesomeIcon icon={faSave} />  Salvar </button></div>
            </div>
       
      </div>
   </div>
</div>
</main>
<ToastContainer/>
        </>
  
    );
  }
}

export default CreateCertTjMg;